import UtenteRepository, {IUtente, UtenteWithPasswordMutation} from "src/types/api/utente-types";
import {GetManyQuery, useEntityHook} from "./use-entity-hook";
import {useMutation, useQueryClient} from "@tanstack/react-query";

const utente = new UtenteRepository();

export const useGetManyUtente = () =>
  useEntityHook<GetManyQuery, IUtente[]>('utente', () => utente.getMany());

export const useGetUtente = (id: string) =>
  useEntityHook<string, IUtente>('utente', () => utente.get(id), id);

export const useGetUtenteByUid = (uid: string) =>
  useEntityHook<string, IUtente>('utente', () => utente.getByUid(uid), uid);

export const useCreateUtente = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (mutationData: UtenteWithPasswordMutation) => {
      return utente.create(mutationData);
    },
    onSuccess: (data) => {
      // Cache result
      queryClient.setQueryData(['utente', data.id], data);
    },
  })
}

export const useUpdateUtente = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (mutationData: UtenteWithPasswordMutation) => {
      return utente.update(mutationData);
    },
    onSuccess: (data) => {
      // Cache result
      queryClient.setQueryData(['utente', data.id], data);
    },
  })
}

export const useGetCurrentLoggedInUserProfile = () =>
  useEntityHook<string, IUtente>('utente', () => utente.getCurrentLoggedInUserProfile());

export const useGetCurrentLoggedInUserRole = () => {
  const { data } = useGetCurrentLoggedInUserProfile();
  return data?.ruolo == null ? "" : data.ruolo;
}
