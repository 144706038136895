import {ReactNode, useEffect, useMemo} from 'react';
import { SvgIcon } from '@mui/material';

import CalendarIcon from "@untitled-ui/icons-react/build/esm/Calendar";
import Users03Icon from "@untitled-ui/icons-react/build/esm/Users03";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import TireRepairOutlinedIcon from '@mui/icons-material/TireRepairOutlined';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CalculateIcon from '@mui/icons-material/Calculate';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { paths } from 'src/routes/paths';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  useGetCurrentLoggedInUserProfile,
  useGetCurrentLoggedInUserRole
} from "../../hooks/entity/utente-hooks";
import {ALLOWED_CONTABILITA_ROLES} from "../../types/api/utente-types";

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

const SvgIconWrapper = (props: any) =>
  <SvgIcon style={{fontSize: "1.4rem"}}
           {...props}>
    {props.children}
  </SvgIcon>

export const useSections = () => {
  const userRole = useGetCurrentLoggedInUserRole();

  useEffect(() => {
    if (userRole != null && userRole !== "") {
      console.log("ruolo:", userRole);
    }
  }, [userRole]);

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: 'Calendario',
            path: paths.calendario,
            icon: (
              <SvgIconWrapper>
                <CalendarIcon />
              </SvgIconWrapper>
            ),
          },
        ],
      },
      {
        subheader: 'Ricezione',
        items: [
          {
            title: 'Anagrafe',
            path: paths.anagrafe,
            icon: (
              <SvgIconWrapper>
                <Users03Icon />
              </SvgIconWrapper>
            ),
          },
          {
            title: 'Veicoli',
            path: paths.veicolo,
            icon: (
              <SvgIconWrapper>
                <LocalShippingOutlinedIcon />
              </SvgIconWrapper>
            ),
          },
        ],
      },
      {
        subheader: 'Officina',
        items: [
          {
            title: 'Cartelle di lavoro',
            path: paths.cdl,
            icon: (
              <SvgIconWrapper>
                <SourceOutlinedIcon />
              </SvgIconWrapper>
            ),
          },
          {
            title: 'Pneumatici',
            path: paths.pneumatici,
            icon: (
              <SvgIconWrapper>
                <TireRepairOutlinedIcon />
              </SvgIconWrapper>
            ),
          },
          {
            title: 'Note cliente',
            path: paths.noteCliente,
            icon: (
              <SvgIconWrapper>
                <TextSnippetIcon />
              </SvgIconWrapper>
            ),
          },
        ]
      },
      {
        subheader: 'Catalogo', items: [
          {
            title: 'Pezzi di ricambio',
            path: paths.pezzoRicambio,
            icon: (
              <SvgIconWrapper>
                <HandymanOutlinedIcon />
              </SvgIconWrapper>
            ),
          },
          {
            title: 'Servizi',
            path: paths.servizio,
            icon: (
              <SvgIconWrapper>
                <HandshakeOutlinedIcon />
              </SvgIconWrapper>
            ),
          },
        ]
      },
      ...(
        ALLOWED_CONTABILITA_ROLES.includes(userRole) ? [{
          subheader: 'Contabilità',
          items: [
            {
              title: 'Conti',
              path: paths.conto,
              icon: (
                <SvgIconWrapper>
                  <CalculateIcon />
                </SvgIconWrapper>
              ),
            },
            {
              title: 'Fatture',
              path: paths.notFoundPage,
              icon: (
                <SvgIconWrapper>
                  <ReceiptLongIcon />
                </SvgIconWrapper>
              ),
            }
          ],
        }] : []
      ),
      ...(
        userRole == "ricezionista" || userRole == "contabile" || userRole == "amministratore" ? [{
          subheader: 'Amministrazione',
          items: [
            ...(userRole === "amministratore" ? [
              {
                title: 'Generale',
                path: paths.amministrazioneGenerale,
                icon: (
                  <SvgIconWrapper>
                    <SettingsSuggestIcon />
                  </SvgIconWrapper>
                )
              },
              {
                title: 'Gestione utenti',
                path: paths.utente,
                icon: (
                  <SvgIconWrapper>
                    <BadgeOutlinedIcon />
                  </SvgIconWrapper>
                ),
              }
            ] : []),
            {
              title: 'Presenze',
              path: paths.presenze,
              icon: (
                <SvgIconWrapper>
                  <AccountBalanceIcon />
                </SvgIconWrapper>
              ),
            }
          ]
        }] : []
      )
    ];
  }, [userRole]);
};

export const MenuFooter = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant={"subtitle2"} fontSize={"13px"} color={"var(--nav-item-color)"} align={"center"} mt={2}>Autocosmo GA v{import.meta.env.VITE_AUTOCOSMO_BUILD_VERSION}-{import.meta.env.VITE_AUTOCOSMO_ENV}</Typography>
    </Box>
  );
}
